body {
  background-color: $background;
  font-family: "Source Sans Pro", sans-serif;
  min-height: 100%;
  // min-height: -webkit-fill-available;
}

.App {
  text-align: center;
  background-color: $background;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: $secondary-text;
  height: 100%;
  // min-height: 100vh;
}

#root {
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100%;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

a {
  color: $highlight;
  text-decoration: none;
}

p {
  text-align: left;
  font-size: 2rem;
  margin: 0px 0px 15px;
  line-height: 1.3;
}

h3 {
  font-size: 3rem;
  color: $headers;
}

h4 {
  font-size: 2.5rem;
  color: $main-text;
}

.main {
  width: 100%;
  max-width: 1100px;
  margin: 0px auto;
}

a:hover {
  color: $highlight;
}

section {
  padding: 0 40px;
  min-height: 100vh;
  @media (max-width: 48em) {
    min-height: -webkit-fill-available;
  }
  width: 100%;
  box-sizing: border-box;
}

section:not(:first-of-type) {
  padding-top: 100px;
  @media (max-width: 48em) {
    padding-top: 20px;
  }
}

.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

// section {
//   padding-top: 100px;
// }

.header > img {
  max-height: 300px;
  border-radius: 50%;
}

.about-section a {
  padding-top: 60px;
}
// .about-section a span {
//   cursor: pointer;
//   position: absolute;
//   bottom: 50px;
//   left: 50%;
//   width: 24px;
//   height: 24px;
//   margin-left: -12px;
//   border-left: 1px solid #fff;
//   border-bottom: 1px solid #fff;
//   -webkit-transform: rotate(-45deg);
//   transform: rotate(-45deg);
//   -webkit-animation: sdb04 2s infinite;
//   animation: sdb04 2s infinite;
//   box-sizing: border-box;
// }
// @-webkit-keyframes sdb04 {
//   0% {
//     -webkit-transform: rotate(-45deg) translate(0, 0);
//   }
//   20% {
//     -webkit-transform: rotate(-45deg) translate(-10px, 10px);
//   }
//   40% {
//     -webkit-transform: rotate(-45deg) translate(0, 0);
//   }
// }
// @keyframes sdb04 {
//   0% {
//     transform: rotate(-45deg) translate(0, 0);
//   }
//   20% {
//     transform: rotate(-45deg) translate(-10px, 10px);
//   }
//   40% {
//     transform: rotate(-45deg) translate(0, 0);
//   }
// }

// .css-typing h2 {
//   border-right: .15em solid orange;
//   font-family: "Courier";
//   font-size: 14px;
//   white-space: nowrap;
//   overflow: hidden;
// }
// .css-typing h2:nth-child(1) {
//   width: 8.7em;
//   -webkit-animation: type 2s steps(40, end);
//   animation: type 2s steps(40, end);
//   -webkit-animation-fill-mode: forwards;
//   animation-fill-mode: forwards;
// }

// .css-typing h2:nth-child(2) {
//   width: 18em;
//   opacity: 0;
//   -webkit-animation: type2 2s steps(40, end);
//   animation: type2 2s steps(40, end);
//   -webkit-animation-delay: 2s;
//   animation-delay: 2s;
//   -webkit-animation-fill-mode: forwards;
//   animation-fill-mode: forwards;
// }

// .css-typing h2:nth-child(3) {
//   width: 13em;
//   opacity: 0;
//   -webkit-animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
//   animation: type3 5s steps(20, end), blink .5s step-end infinite alternate;
//   -webkit-animation-delay: 4s;
//   animation-delay: 4s;
//   -webkit-animation-fill-mode: forwards;
//   animation-fill-mode: forwards;
// }

// @keyframes type {
//   0% {
//     width: 0;
//   }
//   99.9% {
//     border-right: .15em solid orange;
//   }
//   100% {
//     border: none;
//   }
// }

// @-webkit-keyframes type {
//   0% {
//     width: 0;
//   }
//   99.9% {
//     border-right: .15em solid orange;
//   }
//   100% {
//     border: none;
//   }
// }

// @keyframes type2 {
//   0% {
//     width: 0;
//   }
//   1% {
//     opacity: 1;
//   }
//   99.9% {
//     border-right: .15em solid orange;
//   }
//   100% {
//     opacity: 1;
//     border: none;
//   }
// }

// @-webkit-keyframes type2 {
//   0% {
//     width: 0;
//   }
//   1% {
//     opacity: 1;
//   }
//   99.9% {
//     border-right: .15em solid orange;
//   }
//   100% {
//     opacity: 1;
//     border: none;
//   }
// }

// @keyframes type3 {
//   0% {
//     width: 0;
//   }
//   1% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @-webkit-keyframes type3 {
//   0% {
//     width: 0;
//   }
//   1% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 1;
//   }
// }

// @keyframes blink {
//   50% {
//     border-color: transparent;
//   }
// }
// @-webkit-keyframes blink {
//   50% {
//     border-color: tranparent;
//   }
// }
