.projects-section {
  // padding-top: 100px;
  h3 {
      margin: 40px 0px 40px;
      display: flex;
      align-items: center;
      width: 100%;
      &:before {
        content: "#";
        color: $highlight;
        margin-right: 10px;
      }
      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background-color: rgb(48, 60, 85);
        position: relative;
        margin-left: 20px;
      }
    }
    .project-container {
      display: flex;
      margin-bottom: 80px;
      width: 100%;
      flex-wrap: wrap;
      flex-direction: row;
      @media (max-width: 48em) {
      flex-direction: column;
    }
      h4 {
        margin-top: 10px;
        margin-bottom: 40px;
        width: 100%;
        display: flex;
        justify-content: flex-start;
      }
      .project-description {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 60%;
        @media (max-width: 48em) {
            width: 100%;
    }
      }
      .tech-stack {
          @media (max-width: 48em) {
            display: flex;
            flex-wrap: wrap;
    }
        }
        span {
          font-size: 1.8rem;
          color: $highlight;
          padding: 5px;
        }
        .project-links {
          margin-top: 20px;
          width: 100%;
          display: flex;
          justify-content: flex-start;
          @media (max-width: 48em) {
            justify-content: space-around;
    }
          a {
            padding: 10px;
            color: white;
            @media (max-width: 48em) {
            font-size: 3rem;
    }
            &:hover {
              color: $highlight;
            }
          }
        }
      .project-image {
        width: 40%;
        @media (max-width: 48em) {
            width: 100%;
    }
        img {
          max-width: 100%;
          height:auto;
          margin: 0 20px;
          @media (max-width: 48em) {
            margin: 20px auto;
    }
        }
      }
    }
}


// .project-container {
//   display: flex;
//   min-height: 100vh;
//   flex-direction: column;
//   margin: 40px;
// }

// .project-container > img {
//   height: 100%;
// }

// .project-content {
//   display: flex;
// }

// .project-content > img {
//   margin: 20px;
//   max-width: 600px;
// }

// .project-content > p {
//   margin: 20px;
// }

// .tech-stack {
//   display: flex;
// }