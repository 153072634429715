header {
  width: 100%;
  margin: 0 30px;
}

nav {
  height: 100px;
  overflow: hidden;
  background-color: $background;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 2rem;
  padding: 0 50px;
  transition: top 0.2s ease-in-out;
  box-shadow: 0 0 15px 0px rgba(0, 0, 0, 0.3);
  @media (max-width: 48em) {
    justify-content: space-around;
    height: 70px;
  }
  ul {
  display: flex;
  justify-content: flex-end;
  list-style: none;
  li {
    margin: 0 20px;
    a {
      text-transform: uppercase;
      cursor: pointer;
      color: $main-text;
      }
    }
  }   
}

.navbar--hidden {
  top: -100px;
}
