.about-section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  h3 {
      margin: 10px 0px 40px;
      display: flex;
      align-items: center;
      width: 100%;
      @media (max-width: 48em) {
        margin: 10px 0px 20px;
    }
      &:before {
        content: "#";
        color: $highlight;
        margin-right: 10px;
      }
      &:after {
        content: "";
        display: block;
        height: 1px;
        width: 100%;
        background-color: rgb(48, 60, 85);
        position: relative;
        margin-left: 20px;
      }
    }
  .about-content {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
    @media (max-width: 48em) {
      flex-direction: column;
    }
    .about-text {
      width: 60%;
      max-width: 600px;
      @media (max-width: 48em) {
      width: 100%;
      order: 2;
    }
    }
    .about-image {
      width: 40%;
      margin: 30px;
      @media (max-width: 48em) {
        width: 100%;
        justify-content: center;
        order: 1;
        margin: 20px;
    }
      img {
        max-width: 300px;
        border-radius: 50%;
        @media (max-width: 48em) {
        max-width: 200px;
    }
      }
    }
  }
}