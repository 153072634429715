.header {
  display: flex;
  flex-direction: column;
  h1, h2 {
    text-transform: uppercase;
  }
  h1 {
    font-size: 7rem;
    padding-bottom: 15px;
    color: $main-text;
    border-bottom: $main-text solid 1px;
    @media (max-width: 48em) {
    font-size: 6.5rem;
  }
  }
  h2 {
    font-size: 2.9rem;
    padding-top: 10px;
    color: $main-text;
    @media (max-width: 48em) {
    font-size: 2.7rem;
  }
  }
  a span {
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: 30px;
  height: 30px;
  margin-left: -12px;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  -webkit-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-animation: sdb04 2s infinite;
  animation: sdb04 2s infinite;
  box-sizing: border-box;
  cursor: pointer;
  }
}

@-webkit-keyframes sdb04 {
  0% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    -webkit-transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    -webkit-transform: rotate(-45deg) translate(0, 0);
  }
}
@keyframes sdb04 {
  0% {
    transform: rotate(-45deg) translate(0, 0);
  }
  20% {
    transform: rotate(-45deg) translate(-10px, 10px);
  }
  40% {
    transform: rotate(-45deg) translate(0, 0);
  }
}